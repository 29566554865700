import React from 'react';
import { useParams } from 'react-router-dom';
import QRCode from './QRCode'
import { useEffect, useState } from 'react';
import CryptoJS from "crypto-js";
import { Container, Row, Col } from "react-bootstrap";
import './showqr.css';
import logosgu from './logo-half.png';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Moment from "moment"

const ShowQr = () => {

    const { encmsg } = useParams();
    const [url, setUrl] = useState();
    const [objData, setObjData] = useState({});
    const [options, setOptions] = useState();
    const [options2, setOptions2] = useState();

    const [text, setText] = useState('');
    const [btnText, setBtnText] = useState('Copy link to clipboard');
    const [btnClass, setBtnClass] = useState('ba');
    const [btnClass2, setBtnClass2] = useState('bb');
    const inputHandler = event => {
        setText(event.target.value);
    }

    const copy = async () => {
        await navigator.clipboard.writeText(text);
        //alert('Text copied');
        setBtnText("Link copied");
        setBtnClass("bc");
    }

    const downloadCode = () => {
        const qrCanvas = document.getElementById("react-qrcode-logo");
        if (qrCanvas) {
            // Create a new canvas for combined image
            const combinedCanvas = document.createElement("canvas");
            const ctx = combinedCanvas.getContext("2d");
    
            // Set canvas size to match QR code canvas
            combinedCanvas.width = qrCanvas.width;
            combinedCanvas.height = qrCanvas.height + 70; // Adjust height for label
    
            // Draw QR code on combined canvas
            ctx.drawImage(qrCanvas, 0, 0);
    
            // Add label below QR code
            ctx.font = "20px Arial";
            ctx.fillStyle = "#4287f5";
            ctx.fillText("QR for section : "+objData.section_id+"", 10, qrCanvas.height + 30); // Adjust position as needed
            ctx.fillText("Session period : "+Moment(objData.start_dt).format("DD MMM YYYY, h:mm")+" - "+Moment(objData.end_dt).format("DD MMM YYYY, h:mm")+"", 10, qrCanvas.height + 60); // Adjust position as needed
    
            // Convert combined canvas to data URL and initiate download
            const pngUrl = combinedCanvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
    
            const downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
    
            const today = new Date();
            const curTime = today.getFullYear() + "" + (today.getMonth() + 1) + "" + today.getDate() + today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
            downloadLink.download = "QR" + curTime + ".png";
    
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }


    // const encoded = 'eyJzZ3VfcXIiOiJjbGFzcyIsInFyX2NvZGUiOiJleUpoYzJjaU9pQWlPVGhoTmlJc0lDSm5ZeUk2SUNKRUlpd2dJblJzSWpvZ0lqVmpOVFJEWTJKRFl6UWlmUT09In0='; 
    var mesage = "";
    var objMesage = "";
    if (encmsg) {
        try {
            const encodedWord = CryptoJS.enc.Base64.parse(encmsg); // encodedWord via Base64.parse()
            const decoded = CryptoJS.enc.Utf8.stringify(encodedWord);
            mesage = decoded;
            objMesage = JSON.parse(decoded);
        } catch (error) {
            console.log(error)
        }
        // setUrl(mesage)

        console.log(mesage)
        // document.getElementById("qrcode-container").style.display = "block";
    } else {
        alert("Please enter a valid URL");
    }

    useEffect(() => {
        setText(window.location.href)
    }, []);

    useEffect(() => {
        setUrl(mesage)
        setObjData(objMesage)
    }, [mesage]);


    useEffect(() => {
        setOptions({
            ecLevel: 'L', //The error correction level of the QR Code
            enableCORS: false, //Enable crossorigin attribute
            size: 430, //The size of the QR Code
            quietZone: 5, //The size of the quiet zone around the QR Code. This will have the same color as QR Code bgColor
            bgColor: "#FFFFFF", //Background color
            // fgColor: "#ebb434", //Foreground color
            fgColor: "black",
            //logoImage:  logosgu, //The logo image. It can be a url/path or a base64 value
            logoWidth: 180,
            logoHeight: 70,
            logoOpacity: 1,
            qrStyle: "squares" //Style of the QR Code modules - dots or squares
        });
        setOptions2({
            ecLevel: 'L', //The error correction level of the QR Code
            enableCORS: false, //Enable crossorigin attribute
            size: 250, //The size of the QR Code
            quietZone: 5, //The size of the quiet zone around the QR Code. This will have the same color as QR Code bgColor
            bgColor: "#FFFFFF", //Background color
            // fgColor: "#ebb434", //Foreground color
            fgColor: "black",
            //logoImage:  logosgu, //The logo image. It can be a url/path or a base64 value
            logoWidth: 180,
            logoHeight: 70,
            logoOpacity: 1,
            qrStyle: "squares" //Style of the QR Code modules - dots or squares,

        });
    }, []);

    useEffect(() => {
        document.getElementById("qrcode-container").style.display = "block";
    }, []);


    return (

        <div>
            { /*    Data: { mesage }<p /> */}
            <Container>

                <BrowserView>

                    <div className="form">
                        <h2>QR for section : {(objData) ? objData.section_id : ""}</h2>
                        <h2>Session period : {(objData.start_dt) ? Moment(objData.start_dt).format("DD MMM YYYY, h:mm") : ""} - {(objData.end_dt) ? Moment(objData.end_dt).format("DD MMM YYYY, h:mm") : ""}</h2>
                        <Row>
                            <Col xs md lg={6}>
                                <div id="qrcode-container">

                                    {url ?
                                        <>
                                            <QRCode url={url} options={options} />
                                            <Row>
                                                <Col xs md lg={6}>
                                                    <div style={styles.container}>
                                                        <input disabled={true} style={styles.txtcpy} type="hidden" value={text} onChange={inputHandler} />
                                                        <button className={btnClass} onClick={copy} disabled={!text} id="btnQr">{btnText}</button>


                                                    </div>
                                                </Col>

                                                <Col xs md lg={6}>
                                                    <div style={styles.container}>
                                                        <button className={btnClass2} onClick={() => downloadCode()}>
                                                            Download Image
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                        : null
                                    }
                                </div>
                            </Col>

                            <Col xs md lg={6} >
                                { /* <img src={logosgu} width={180} height={70}></img> */}
                                <div id="noted">Note :
                                    <ol>
                                        <li>Please scan QR using SGU mobile app.</li>
                                        <li>QR scanning only eligible 30 minutes before and after the class start.</li>
                                        <li>Each QR are created uniquely, therefore cannot be use for multiple classes.</li>
                                    </ol>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </BrowserView>
                <MobileView>

                    <div className="form">
                        <h2>QR for section : {(objData) ? objData.section_id : ""}</h2>
                        <h2>Session period : {(objData.start_dt) ? Moment(objData.start_dt).format("DD MMM YYYY, h:mm") : ""} - {(objData.end_dt) ? Moment(objData.end_dt).format("DD MMM YYYY, h:mm") : ""}</h2>
                        <Row>
                            <Col xs md lg={12}>
                                <div id="qrcode-container">

                                    {url ?
                                        <>
                                            <QRCode url={url} options={options2} />
                                            <div style={styles.container}>
                                                <input disabled={true} style={styles.txtcpy} type="hidden" value={text} onChange={inputHandler} />
                                                <button className={btnClass} onClick={copy} disabled={!text} id="btnQr">{btnText}</button>


                                            </div>
                                            <div style={styles.container}>
                                                <button className={btnClass2} onClick={() => downloadCode()}>
                                                    Download Image
                                                </button>
                                            </div>


                                        </>
                                        : null
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs md lg={12} >
                                { /* <img src={logosgu} width={180} height={70}></img> */}
                                <div id="noted2">Note :
                                    <ol>
                                        <li>Please scan QR using SGU mobile app.</li>
                                        <li>QR scanning only eligible 30 minutes before and after the class start.</li>
                                        <li>Each QR are created uniquely, therefore cannot be use for multiple classes.</li>
                                    </ol>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </MobileView>
            </Container>

        </div>


    );
}
export default ShowQr;

const styles = {
    container: {
        margin: "25px 50px 75px 20px !important",
        align: "center"
    },
    txtcpy: {
        width: "200px !important",
        disabled: true
    }
}
