import * as React from "react";
import { BrowserRouter,Routes, Route, Outlet, Link , Switch} from "react-router-dom";
//import Atd from "./Atd";
import ShowQr from "./ShowQr";
//import User from './User';


export default function App() {
  return (
    <div>
     {/* <h2>Server is running</h2> */}

      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
  
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
           <Route path='/showqr/:encmsg' element={<ShowQr />} />
        {/*  <Route path="/atd" element={<Atd text='{"sgu_qr":"class","qr":"eyJhc2ciOiAiOThhNiIsICJnYyI6ICJEIiwgInRsIjogIjVjNTRDY2JDYzQifQ=="}' />}/>
          <Route path='/user/:userName' element={<User />} />
         
           Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
     
    </div>
  );
}

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. 
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/atd">QR</Link>
          </li>
          <li>
            <Link to="/user/eyJzZ3VfcXIiOiJjbGFzcyIsInFyIjoiZXlKaGMyY2lPaUFpT1RoaE5pSXNJQ0puWXlJNklDSkVJaXdnSW5Sc0lqb2dJalZqTlRSRFkySkRZelFpZlE9PSJ9">User</Link>
          </li>
          <li>
            <Link to="/nothing-here">Nothing Here</Link>
          </li>
        </ul>
      </nav>

      <hr />
*/}
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
      
    </div>
  );
}

function Home() {
  return (
    <div>
      <h3>No Data to Display</h3>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
